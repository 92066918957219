var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-end p-2 edit-field",
    class: {
      'total-text': _vm.item.item.is_total,
      focus: _vm.is_focused
    },
    attrs: {
      "contenteditable": !_vm.item.item.is_total
    },
    on: {
      "blur": _vm.blurTextEdit,
      "input": _vm.setPlan,
      "focus": _vm.clickTextEdit,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.enter.apply(null, arguments);
      }
    }
  }, [_vm._v(" 0,00 ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }